import React from 'react';
import useUserAccounts from './hooks/useAccounts';
import Withdrawals from './components/Withdrawals';
import Refills from './components/Refills';
import './assets/style.css';

function App() {

  const { accounts, loading, error } = useUserAccounts();

  return (
    <div style={{
      backgroundColor: 'white',
      padding: 40,
    }}>
      <h1>Текущие необработанные действия</h1>
      
      {loading ? (
        <p>Идет загрузка...</p>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <div>
          {(accounts ?? []).length > 0 && (
            <>
              <Withdrawals/>
              <Refills/>
            </>
          )}
        </div>
      )}
      
    </div>
  );
}
 
export default App;