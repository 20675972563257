import { useEffect, useState } from "react"
import { getWithdrawals } from "../http/request";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProcessWithdrawal from "./ProcessWithdrawal";

const Withdrawals = () => {

    const [withdrawals, setWithdrawals] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [showModal, setShowModal] = useState(false);
    const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);

    const removeWithdrawal = (id) => {
      setWithdrawals(withdrawals.filter(withdrawal => withdrawal._id !== id));
    }

    
    const __load_async = async () => {
      try {
        let w = await getWithdrawals();
        setWithdrawals(w);
        setLoading(false);
      } catch (e) {

      }
    }

    useEffect(() => {
      __load_async();
    },[])

    if (loading) {
      return (<div>Выводы загружаются...</div>)
    }

    const handleSign = (withdrawal) => {
      setSelectedWithdrawal(withdrawal);
      setShowModal(true);
    }

    return (
        <div>
          { withdrawals.length == 0 &&  <div>Необработанные выводы отсутсвуют</div> }
          { withdrawals.length > 0 && (
            <div>
              <div style={{marginTop: 5, marginBottom: 5, fonsSize: 16, fontWeight: 600}}>Есть { withdrawals.length } необработанных вывода</div>
              <talbe className="widthTable">
                <tr>
                  <td>Дата</td>
                  <td>Пользователь</td>
                  <td>Сумма</td>
                  <td>Куда</td>
                  <td>Подписать</td>
                </tr>
                {withdrawals.map((withdrawal) => {
                  return (
                    <tr key={"id_" + withdrawal._id}>
                      <td>
                        { (new Date(withdrawal.created_on)).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                        }).replace(',', '') }
                      </td>
                      <td>{ withdrawal.user.email }</td>
                      <td>{ withdrawal.amount_total_crypto } { withdrawal.currency_symbol }</td>
                      <td>{ withdrawal.token_to }</td>
                      <td>
                        <button onClick={() => handleSign(withdrawal)}>
                          Подписать
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </talbe>


              <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Подписание транзакции</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {selectedWithdrawal && <ProcessWithdrawal withdrawal={selectedWithdrawal} removeWithdrawal={removeWithdrawal}/>}
                </Modal.Body>
              </Modal>

            </div>
          )}
        </div>
    )
}

export default Withdrawals