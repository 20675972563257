import { $authHost } from './index.js'

export const getWithdrawals = async () => {
    const {data} = await $authHost.get('api/ledger/withdrawals');
    return data
}

export const getRefills = async () => {
    const {data} = await $authHost.get('api/ledger/refills');
    return data
}

export const setWithdrawalSuccess = async (id, hash) => {
    const {data} = await $authHost.post('api/ledger/withdrawal/success', {
        id: id,
        hash: hash
    });
    return data
}

export const setRefillSuccess = async (id, hash) => {
    const {data} = await $authHost.post('api/ledger/refill/success', {
        id: id,
        hash: hash
    });
    return data
}
