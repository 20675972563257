import axios from 'axios'

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const authInterceptor = confing => {
    confing.headers.authorization = `Bearer ${process.env.REACT_APP_API_X3_TOKEN}`
    return confing
}

$authHost.interceptors.request.use(authInterceptor)

export {
    $authHost,
}