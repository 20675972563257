import { useSignAndBroadcastTransaction, useRequestAccount } from '@ledgerhq/wallet-api-client-react';
import { useCallback, useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';

 
function useTransactionSigner(amount, recipient, account, symbol) {
  
  const {
    signAndBroadcastTransaction,
    pending,
    transactionHash,
    error,
  } = useSignAndBroadcastTransaction();
 
 
  const handleSignTransaction = useCallback(async () => {
    if (!account) return;
  
    let transactionData = {};

    if (symbol == 'BTC') {

      transactionData = {
        family: "bitcoin",
        amount: new BigNumber(amount * Math.pow(10,8)), 
        recipient: recipient,
        // feePerByte: new BigNumber(feePerByte),
      };

    } else if (symbol == "USDt TRC20") {

      transactionData = {
        family: "tron",
        amount: new BigNumber(amount * Math.pow(10,6)), 
        recipient: recipient,
        mode: "send",
      };

    }

    console.log(transactionData);
  
    try {
      await signAndBroadcastTransaction(account.id, transactionData);
    } catch (e) {
      console.error(e);
    }
  }, [account, signAndBroadcastTransaction, amount, recipient]);
  
 
  return {
    handleSignTransaction,
    pending,
    transactionHash,
    error
  };
}
 
export default useTransactionSigner;