import { useEffect, useState } from "react"
import { getWithdrawals, setRefillSuccess, setWithdrawalSuccess } from "../http/request";
import useTransactionSigner from '../hooks/useSignTransaction';
import { useSignAndBroadcastTransaction, useRequestAccount } from '@ledgerhq/wallet-api-client-react';
import { address_birfinex, address_birfinex_exp } from "../utils/bitfinex";

const ProcessRefill = ({ refill, removeRefill }) => {

  const amount = refill.amount_received;
  const email = refill.user.email;
  const recipient = address_birfinex[refill.currency_symbol];
  const currency_symbol = refill.currency_symbol;
  const id = refill._id;

  

  const { requestAccount, account } = useRequestAccount();

  const { handleSignTransaction, pending, transactionHash, error: signError } = useTransactionSigner(amount, recipient, account, currency_symbol);
  const [ mainError, setMainError ] = useState('');

  useEffect(() => {
    requestAccount();
  }, [requestAccount]);

  const __save_async = async(hash) => {
    try { 
      await setRefillSuccess(id, hash)
      removeRefill(id)
    } catch (e) {
      setMainError("ВНИМАНИЕ! Транзакция совершена, но на сервере не отмечена! СРОЧНО! Свяжитель с программистом! Передайте ID проблемного вывода: " + id);
    }
  }

  useEffect(() => {
    if (transactionHash) {
      __save_async(transactionHash);
    }
  }, [transactionHash]);

  return (
    <div>
      { !account && <div>Выберите кошелек списания</div> }
      { account && <div>

        { mainError !== "" && <div style={{color: 'red', fontSize: 20}}>{ mainError }</div> }

        <div>Текущий баланс на кошельке { account.currency }: <strong>{ parseFloat(account.balance.toString() / Math.pow(10, address_birfinex_exp[currency_symbol])) }</strong></div>

        <br/>

        <table className="tabelModal">
          <tr>
            <td>ID в базе X3</td>
            <td>{ id }</td>
          </tr>
          <tr>
            <td>Пользователь</td>
            <td>{ email }</td>
          </tr>
          <tr>
            <td>Сумма</td>
            <td>{ amount } { currency_symbol }</td>
          </tr>
          <tr>
            <td>Адрес</td>
            <td>{ recipient }</td>
          </tr>
          <tr>
            <td colspan="2">

              <button onClick={handleSignTransaction} disabled={pending}>
                Подписать
              </button>
              
            </td>
          </tr>
        </table>

        <br/>

        {pending && <p>Подписываем...</p>}
        {signError && <p>Ошибка: {signError.toString()}</p>}
        {transactionHash && <p>Транзакция отправлена (transactionHash): {transactionHash}</p>}
        
      </div>}
    </div>
  )

}

export default ProcessRefill;