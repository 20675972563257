import { useEffect, useState } from "react"
import { getRefills } from "../http/request";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProcessRefill from "./ProcessRefill";
import { address_birfinex } from "../utils/bitfinex";

const Refills = () => {
  
    const [refills, setRefills] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [showModal, setShowModal] = useState(false);
    const [selectedRefill, setSelectedRefills] = useState(null);

    const removeRefill = (id) => {
      setRefills(refills.filter(refill => refill._id !== id));
    }

    
    const __load_async = async () => {
      try {
        let w = await getRefills();
        setRefills(w);
        setLoading(false);
      } catch (e) {

      }
    }

    useEffect(() => {
      __load_async();
    },[])

    if (loading) {
      return (<div>Пополнения загружаются...</div>)
    }

    const handleSign = (refill) => {
      setSelectedRefills(refill);
      setShowModal(true);
    }

    return (
        <div>
          { refills.length == 0 &&  <div>Необработанные поступления отсутсвуют</div> }
          { refills.length > 0 && (
            <div>
              <div style={{marginTop: 5, marginBottom: 5, fonsSize: 16, fontWeight: 600}}>Есть { refills.length } необработанных поступлений</div>
              <talbe className="widthTable">
                <tr>
                  <td>Дата</td>
                  <td>Пользователь</td>
                  <td>Сумма</td>
                  <td>Куда</td>
                  <td>Подписать</td>
                </tr>
                {refills.map((refill) => {
                  return (
                    <tr key={"id_" + refill._id}>
                      <td>
                        { (new Date(refill.created_on)).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                        }).replace(',', '') }
                      </td>
                      <td>{ refill.user.email }</td>
                      <td>{ refill.amount_received } { refill.currency_symbol }</td>
                      <td>{ address_birfinex[refill.currency_symbol] }</td>
                      <td>
                        <button onClick={() => handleSign(refill)}>
                          Подписать
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </talbe>


              <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Подписание транзакции</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {selectedRefill && <ProcessRefill refill={selectedRefill} removeRefill={removeRefill}/>}
                </Modal.Body>
              </Modal>

            </div>
          )}
        </div>
    )
}

export default Refills